// 职位管理相关接口
import request from '@/util/request'
export default {

    // 发布职位
    posiSet(query){
        return request({
            url:"/api/position.index/set",
            method:"post",
            data:{...query}
        })
    },
    // 职位列表
    posiList(query){
        return request({
            url:"/api/position.index/lists",
            method:"post",
            data:{...query}
        })
    },
    // 职位详情
    posiDetail(query){
        return request({
            url:"/api/position.index/detail",
            method:"post",
            data:{...query}
        })
    },
    // (取消)收藏职位
    collectPosi(query){
        return request({
            url:"/api/position.index/collection",
            method:"post",
            data:{...query}
        })
    },
    // 热门职类
    hotPosi(query){
        return request({
            url:"/api/position.index/seeMorm",
            method:"post",
            data:{...query}
        })
    },
    // 投递简历
    addDelivery(query){
        return request({
            url:"/api/user/addDelivery",
            method:"post",
            data:{...query}
        })
    },
    // 投递简历
    userH5Pay(query){
        return request({
            url:"/api/position.index/userflush",
            method:"post",
            data:{...query}
        })
    },
    // 个人职位收藏列表
    myCollection(){
        return request({
            url:"/api/position.index/myCollection",
            method:"post"
        })
    },
    // 看过的职位
    readRecord(){
        return request({
            url:"/api/position.index/readRecord",
            method:"post"
        })
    },
    // 设置沟通过的职位
    setConnect(query){
        return request({
            url:"/api/position.index/setConnect",
            method:"post",
            data:{...query}
        })
    },
    // 获取沟通中的职位
    getConnect(){
        return request({
            url:"/api/position.index/getConnect",
            method:"post"
        })
    },
    // 已投递的职位列表
    getDeliverList(){
        return request({
            url:"/api/user/deliveryList",
            method:'post'
        })
    },
    // 用户端面试列表
    getInterviewList(){
        return request({
            url:"/api/user/interviewList",
            method:"post"
        })
    },
    // 职位下线
    setOnline(query){
        return request({
            url:"/api/position.index/setOnline",
            method:"post",
            data:{...query}
        })
    },
    // 职位刷新
    setFlush(query){
        return request({
            url:"/api/position.index/flush",
            method:"post",
            data:{...query}
        })
    },
    // 置顶急招职位
    setAct(query){
        return request({
            url:"/api/position.index/setAct",
            method:"post",
            data:{...query}
        })
    },
    // 删除职位
    delPosi(query){
        return request({
            url:"/api/position.index/del",
            method:"post",
            data:{...query}
        })
    },
    // 发布的职位数量
    posiNum(){
        return request({
            url:"/api/position.index/getMyPositionNum",
            method:"post"
        })
    },
    // 生成分享二维码
    getShare(id){
        return request({
            url:`/api/position.index/getShare?id=${id}`,
            method:"get"
        })
    }
}