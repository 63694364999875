<template>
	<div class="my-resume">
		<logged-heard></logged-heard>
		<div @click.stop="hideEmoji" class="my-resume-ceneter space-between">
			<div>
				<tabbar-model :typeId="1" :typeIdOne="2" :typeIndex="9"></tabbar-model>
			</div>
			<div class="my-resume-ceneter-router one">
				<div class="interaction-heard row">
					<div class="heard-left space-between font-normal">
						<div>近期联系人</div>
						<div class="row-center unread pointer" @click="unreadClick">
							<div v-if="unreadShow"></div>
							<img src="../../assets/glxt/27.png" alt="" v-else />
							<div>未读</div>
						</div>
					</div>
					<div class="heard-right one space-between font-normal">
						<div class="user-right row-center">
							<div v-if="posiObj" class="row">
								<div>{{ posiObj.hr.user_name }}</div>
								<div class="row-center user-job">
									<span>{{ posiObj.ent.ent_name }}</span>
									<div>|</div>
									<span>HR</span>
								</div>
							</div>
						</div>
						<div class="text-blue pointer position-relative" @click="scanClick">
							<span>扫一扫，热招职位抢先知道</span>
							<div class="position-absolute top-0 right-0 scan-img" v-if="scanShow">
								<img src="../../assets/forget/18.png" alt="" />
								<div class="code-img position-absolute top-0 left-0">
									<img src="../../assets/forget/2.jpg" alt="" />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div @click.stop="hideEmoji" class="interaction-center row">
					<div class="center-user-list">
						<!-- 暂无联系人 -->
						<div class="no-user direction text-main font-lighter" v-if="chartList.length == 0">
							暂无联系人
						</div>
						<!-- 有联系人 -->
						<div class="have-user" v-else>
							<div class="have-user-item row-center pointer" v-for="item in chartList"
								:key="item.userProfile.userID"
								:class="pointerIndex == item.userProfile.userID ? 'pointerClass' : ''"
								@mouseenter="mouseenterClick(item.userProfile.userID)" @click="userClick(item.userProfile.userID, item.lastMessage.payload.extension)">
								<img :src="item.userProfile.avatar ? item.userProfile.avatar : avatar" alt="" />
								<div class="user-item-center one direction-between">
									<div class="space-between item-user-text">
										<div class="text-main font-normal">
											{{ item.userProfile.nick ? item.userProfile.nick : "未知" }}
										</div>
										<!-- 计算时间 -->
										{{item.lastMessage ? $util.dateFormat(item.lastMessage.lastTime * 1000) : ""}}
									</div>
									<div class="text-ellipsis ellip-text"
										style="word-break: break-all; max-width: 15.625rem">
										<span v-if="item.unreadCount">【{{ item.unreadCount == 0 ? "已读" : "未读" }}】</span>
										<span v-if="item.lastMessage.payload.data !== 'inte' &&item.lastMessage.payload.data !== 'cvText'">
											{{item.lastMessage ? item.lastMessage.payload.description : ""}}
										</span>
										<span v-if="item.lastMessage.payload.data == 'inte'">邀请你的面试</span>
										<span v-if="item.lastMessage.payload.data == 'cvText'">附件简历已发出</span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="center-user-say one">
						<!-- 暂无联系 -->
						<div class="say-no direction" v-if="chartId == ''">
							<img src="../../assets/glxt/43.png" alt="" />
							<div class="font-normal">
								<span class="text-main">Tips</span>：您与企业HR在线沟通的记录都在这里
							</div>
						</div>
						<!-- 聊天界面 -->
						<div class="say-interface column" v-else>
							<div class="say-interface-heard space-between" v-if="posiObj" @click="navTo">
								<div class="column-between heard-left">
									<div class="row-center left-top font-normal">
										<div>沟通职位：</div>
										<div>{{ posiObj.position_name }}</div>
										<div>{{ posiObj.salary_min }}-{{ posiObj.salary_max }}</div>
										<div>{{ posiObj.province }}</div>
									</div>
									<div class="row-center text-blue left-bottom font-normal" v-if="posiObj.ent">
										<div v-for="(item, index) in posiObj.ent.welfare.slice(0, 5)" :key="item">
											{{ item }}
											<span v-if="posiObj.ent.welfare.length > 5 && index == 4">...</span>
										</div>
									</div>
								</div>
								<div class="row-center heard-right font-normal" v-if="posiObj.ent">
									<div class="right-left column-between">
										<div>{{ posiObj.ent.ent_name }}</div>
										<div class="row-center right-left-bottom">
											<div>{{ posiObj.ent.other_info.nature.text }}</div>
											<div>{{ posiObj.ent.other_info.scale.text }}</div>
										</div>
									</div>
									<img :src="posiObj.ent.logo_url" alt="" />
								</div>
							</div>
							<!-- 对话框 -->
							<div class="chart-rightr-item one" @scroll="scrollEventSay">
								<div v-for="(item, index) in wordList" :key="item.id">
									<!-- 时间 -->
									<div class="word-time flex-center font-normal" v-show="index == 0">
										{{ $util.dateFormat(item.time * 1000) }}
									</div>
									<!-- index不为第一个切两条消息相差5分钟才显示这个时间 -->
									<div class="word-time flex-center font-normal" v-if="index !== 0">
										{{ item.time - wordList[index - 1].time > 5 * 60 ? $util.dateFormat(item.time * 1000) : "" }}
									</div>
									<!-- 对方的 -->
									<div class="word row-center" v-if="item.mineMsg == false">
										<img :src="item.avatar ? item.avatar : avatar" alt="" />
										<!-- 因为文本中可能包含表情包，所以得借助方法解析对方的文本 -->
										<div class="flex-center" style="word-break: break-all"
											v-html="emoji.decoder.decode(item.contactText)"></div>
									</div>
									<!-- 对方发送的电话邀请交换-->
									<div class="el-word-phone row-center" v-if="item.phoneShow">
										<img :src="item.avatar ? item.avatar : avatar" alt="" />
										<div class="word-phone-div font-normal">
											<div>我想和你交换联系方式 您是否同意</div>
											<div class="word-phone-button row">
												<div class="pointer" @click="sendPhoneOne(item.id)">拒绝</div>
												<div class="text-blue pointer" @click="sendPhone(item.id)">同意</div>
											</div>
										</div>
									</div>
									<!-- 对方发送的面试邀请-->
									<div class="el-word-phone row-center" v-if="item.invite">
										<img :src="item.avatar ? item.avatar : avatar" alt="" />
										<div class="el-word-phone-div-text font-normal">
											<div class="row">
												<img :src="item.avatar ? item.avatar : avatar" alt="" />
												<span
													v-if="posiObj && posiObj.ent">{{ posiObj.ent.ent_name }}给你发出的面试邀请，点击查看</span>
											</div>
											<div class="word-phone-button-text text-blue pointer"
												@click="checkInviteClick(item.contactText)">
												立即查看
											</div>
										</div>
									</div>

									<!-- 交换联系方式,交换简历 -->
									<div class="exchange flex-center font-normal text-main"
										v-if="item.connect && item.phoneShow == false">
										{{ item.contactText }}
									</div>

									<!-- 对方发送的电话邀请交换-->
									<div class="el-word-phone row-center" v-if="item.cv">
										<img :src="item.avatar ? item.avatar : avatar" alt="" />
										<div class="word-phone-div font-normal">
											<div>我想获取你的简历 您是否同意</div>
											<div class="word-phone-button row">
												<div class="pointer" @click="cvClickOne">拒绝</div>
												<div class="text-blue pointer" @click="cvClick">同意</div>
											</div>
										</div>
									</div>
									<!-- 我的 -->
									<div class="word-my row" v-if="item.mineMsg">
										<div class="have-read flex-center text-white"
											:class="item.read == false ? 'read-false' : ''">
											{{ item.read ? "已读" : "未读" }}
										</div>
										<div class="row-center word-my-center">
											<div class="text-white bg-blue flex-center"
												v-html="emoji.decoder.decode(item.contactText)"></div>
											<img :src="item.avatar ? item.avatar : avatar" alt="" />
										</div>
									</div>
								</div>
							</div>
							<!-- 发送框 -->
							<div class="position-relative">
								<!-- 表情包弹窗列表-->
								<div class="position-absolute el-expression" v-if="emojiShow">
									<img class="pointer" v-for="(emojiItem, emojiKey, index) in emoji.map" :key="index"
										:src="emoji.url + emojiItem" @click="selectEmoji(emojiKey)" />
								</div>
								<!-- 交换电话弹窗列表 -->
								<div class="position-absolute el-phone-exchange" v-if="phoneShow">
									<img src="../../assets/glxt/41.png" alt="" />
									<div class="el-phone-center position-absolute">
										<div>请求对方交换电话</div>
										<div class="el-phone-tab row-center">
											<div class="flex-center pointer" @click="phoneShow = false">
												取消
											</div>
											<div class="text-white bg-blue flex-center pointer" @click="phoneClick">
												确定
											</div>
										</div>
									</div>
								</div>
								<!-- 发送简历弹窗列表 -->
								<div class="position-absolute el-phone-exchange el-phone-exchange-one" v-if="cvShow">
									<img src="../../assets/glxt/41.png" alt="" />
									<div class="el-phone-center position-absolute">
										<div>请求发送附件简历给对方</div>
										<div class="el-phone-tab row-center">
											<div class="flex-center pointer" @click="cvShow = false">取消</div>
											<div class="text-white bg-blue flex-center pointer" @click="cvClick">
												确定
											</div>
										</div>
									</div>
								</div>
								<div class="send-box column">
									<div class="send-box-heard space-between">
										<div class="send-heard-left row-center">
											<img src="../../assets/forget/17.png" class="pointer" alt=""
												@click.stop="emojiShowClick" />
											<div class="heard-left-cv row-center pointer" @click.stop="cvShow = true">
												<img src="../../assets/glxt/29.png" alt="" />
												<div>简历</div>
											</div>
											<div class="heard-left-cv heard-left-cv-one row-center pointer"
												@click.stop="phoneClickEnt">
												<img src="../../assets/glxt/30.png" alt="" />
												<div :class="userDetail.privates.protect_phone == 1 ? 'phone-text' : ''">
													电话
												</div>
											</div>
										</div>
										<div class="el-send-heard-right flex-center text-blue pointer"
											@click="interestedClick">
											不感兴趣
										</div>
									</div>
									<div class="send-box-input one">
										<el-input type="textarea" placeholder="请输入内容" v-model="textarea"
											@keyup.enter.native="handleSearchClick">
										</el-input>
									</div>
									<div class="send-feet-button row-end">
										<div class="flex-center pointer" :class="areaShow ? 'area-active' : ''"
											@click="sendClick">
											发送
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<mask-resume ref="maskResume" width="482px" title="请选择需要投递的附件简历" :btnCancel="false" @submitClick="submitClick">
			<div class="mask-resume font-normal">
				<div class="text-main">文件附件</div>
				<div class="row-center resume-div" v-if="annexObj">
					<i class="el-icon-document"></i>
					<div>{{ annexObj.annex_name }}</div>
				</div>
				<div class="not-resume space-between" v-if="msgText">
					<div class="not-left row-center">
						<img src="../../assets/forget/19.png" alt="" />
						<div>{{ msgText }}</div>
					</div>
					<div class="not-right text-white flex-center font-normal pointer" @click="toUploadClick">
						去上传
					</div>
				</div>
			</div>
		</mask-resume>
		<!-- 上传简历 -->
		<appendix-cv ref="appendixCv"></appendix-cv>
		
		<!-- 接受的面试邀请 -->
		<mask-model ref="maskModel" width="482px" sureContent="接受" :btnCancel="false" :rejectBtn="true"
			@submitClick="submitStatusClick" @rejectClick="rejectClick" title="接收面试邀请">
			<div class="update-eidt" v-if="inviteObj">
				<div class="row-center eift-item">
					<div class="text-main font-normal">面试职位：</div>
					<div class="div-select">
						{{ inviteObj.position }}
					</div>
				</div>
				<div class="row-center eift-item-one">
					<div class="text-main font-normal">面试时间：</div>
					<div class="row select-item">
						<div class="select-item-div select-item-div-one">
							{{ inviteObj.time }}
						</div>
					</div>
				</div>
				<div class="row-center eift-item">
					<div class="text-main font-normal">面试地址：</div>
					<div class="div-select">
						{{ inviteObj.address }}
					</div>
				</div>
				<div class="row-center eift-item">
					<div class="text-main font-normal">联系人姓名：</div>
					<div class="div-select div-select-one">
						{{ inviteObj.name }}
					</div>
				</div>
				<div class="row-center eift-item">
					<div class="text-main font-normal">联系方式：</div>
					<div class="div-select">
						{{ inviteObj.phone }}
					</div>
				</div>
				<div class="row-center eift-item">
					<div class="text-main font-normal">备注事项：</div>
					<div class="div-select">
						{{ inviteObj.remark }}
					</div>
				</div>
			</div>
		</mask-model>
		<feet-model></feet-model>
	</div>
</template>

<script>
	import loggedHeard from "../../components/loggedHeard.vue";
	import feetModel from "../../components/feet.vue";
	import tabbarModel from "../../components/tabbar.vue";
	import EmojiDecoder from "../../util/EmojiDecoder";
	import {
		emojiName,
		emojiUrl,
		emojiMap
	} from "@/util/emojiMap.js";
	import maskResume from "../../components/mask.vue";
	import maskModel from "../../components/mask.vue";
	import appendixCv from "../../components/appendixCv.vue";
	import TIM from "tim-js-sdk";
	import posiApi from "../../api/position";
	import {
		mapState
	} from "vuex";
	import toolApi from "../../api/tool";
	import userApi from "../../api/user";
	export default {
		components: {
			loggedHeard,
			feetModel,
			tabbarModel,
			maskResume,
			appendixCv,
			maskModel
		},
		computed: {
			// 2. 解构状态作为计算属性
			...mapState(["userDetail"]),
		},
		data() {
			return {
				avatar: require("../../assets/login/922028.png"),
				//定义表情列表
				emoji: {
					url: emojiUrl,
					map: emojiMap,
					show: false,
					decoder: new EmojiDecoder(emojiUrl, emojiMap),
				},
				// 表情包列表弹窗是否展示
				emojiShow: false,
				// 交换电话弹窗是否显示
				phoneShow: false,
				// 发送简历的弹窗
				cvShow: false,
				unreadShow: true,
				pointerIndex: "",
				// 选中的哪一个用户的id
				chartId: "",
				scanShow: false,
				chartList: [],
				textarea: "",
				// 发送按钮的样式
				areaShow: false,
				wordList: [],
				// 自己的id
				userId: "",
				destory: true,
				// 判断与当前人聊天的数据是否已经拉取完
				isCompleted: true,
				nextReqMessageID: "", //拉取更多信息时的id参数
				concatShow: true,
				scrollHeight: 0,
				// 职位id
				posiId: "",
				// 职位详情的数据
				posiObj: null,
				inviteObj: null,
				msgText: "",
				// 扩展字段
				extension: null,
				annexObj: null,
				statusObj: {
					position_id: "",
					status: "",
				},
				interDataList: [],
				exchangeList: []
			};
		},
		created() {
			this.getUserInfo();
			const userInfo = JSON.parse(localStorage.getItem("userInfo"));
			if (this.userDetail.user_type == 2) {
				this.toggleClick();
				this.$router.push({
					path: "/"
				});
				return;
			}
			this.userId = userInfo.user_id;
			// 接收路由跳转过来的用户参数id
			if (this.$route.query.id) {
				this.chartId = this.$route.query.id;
				this.pointerIndex = this.$route.query.id;
				this.posiId = this.$route.query.posiId;
				this.posiDetail(this.posiId);
				this.userClick(this.chartId);
			}
		},
		mounted() {
			if (this.chartId) {
				this.scrollEvent();
			}
			this.getInterviewList();
			setTimeout(() => {
				this.readyList();
			}, 1000);
			this.monitor();
			this.getExchangeList();
		},
		watch: {
			textarea(val) {
				if (val.length > 0) {
					this.areaShow = true;
				} else {
					this.areaShow = false;
				}
			},
		},
		methods: {
			// hyz：点击其他空白隐藏emoji
			hideEmoji() {
				this.emojiShow = false;
				this.phoneShow = false;
				this.cvShow = false;
			},
			// 更新交换电话
			upExchangePhone(id, status){
				if(!id) return;
				userApi.upExchangePhone({'im_id':id,'status':status}).then((res) => {
					// console.log('===upExchangePhone===',res)
				})
			},
			// 获取交换列表
			getExchangeList(){
				var that = this;
				userApi.getExchangeList({'is_hr':0}).then((res) => {
					// console.log('===getExchangeList===',res)
					that.exchangeList = res.data.data;
				})
			},
			// 获取面试列表
			getInterviewList() {
				var that = this;
				userApi.interviewList().then((res) => {
					if (res.code == 200) {
						that.interDataList = res.data.data
					}
				});
			},
			// 获取用户信息
			getUserInfo() {
				userApi.userDetail().then((res) => {
					if (res.code == 200) {
						if (res.data.user_type == 2) {
							this.toggleClick();
							this.$router.push({
								path: "/"
							});
							return;
						}
						this.$store.commit("SET_USER", res.data);
					}
				});
			},
			toggleClick() {
				this.$emit("toggleClick");
			},
			emojiShowClick() {
				this.emojiShow = !this.emojiShow;
			},
			phoneClickEnt() {
				if (this.userDetail.privates.protect_phone == 1) {
					this.$util.msg("你已开启隐私保护", "warning");
					return;
				}
				this.phoneShow = !this.phoneShow;
			},
			navTo() {
				// console.log(this.posiObj);
				this.$router.push({
					path: "/Position-details",
					query: {
						id: this.posiObj.id
					},
				});
			},
			// 设置沟通中的职位
			setConnect(query) {
				posiApi.setConnect(query).then((res) => {
					// console.log(res);
				});
			},
			submitStatusClick() {
				this.statusObj.position_id = this.inviteObj.positionValue;
				this.statusObj.status = 1;
				this.setStateInterview();
			},
			rejectClick() {
				this.statusObj.position_id = this.inviteObj.positionValue;
				this.statusObj.status = 2;
				this.setStateInterview();
			},
			setStateInterview() {
				var that = this;
				var item = this.inviteObj;
				if (this.interDataList.length) {
					for (var i = 0; i < this.interDataList.length; i++) {
						let val = this.interDataList[i];
						if (val.position_id == item.positionValue && val.status == 0) {
							// this.inviteObj = item;
							// this.$refs.maskModel.show();
							userApi.setStateInterview(this.statusObj).then((res) => {
								if (res.code == 200) {
									that.$util.msg(res.data);
									that.sendSay({
										data: "text",
										description: res.data,
										extension: JSON.stringify(this.extension),
									});
									that.getInterviewList();
								}
							});
						} else {
							this.$message({
								message: '面试邀请只能点击一次',
								type: 'warning'
							});
						};
						break; //中断循环
					}
				} else {
					console.log('===== checkInviteClick error =====');
				}
			},
			// 查看面试邀请
			checkInviteClick(item) {
				this.inviteObj = item;
				this.$refs.maskModel.show();
			},
			// 获取职位信息
			posiDetail(id) {
				posiApi.posiDetail({
					id
				}).then((res) => {
					if (res.code == 200) {
						this.posiObj = res.data;
					}
				});
			},
			// 监听im相关数据
			monitor() {
				let onMessageReceived = (event) => {
					let obj;
					let payload = event.data[0].payload;
					if (event.data[0].from !== this.chartId) return;
					if (payload.data == "text") {
						obj = {
							mineMsg: false,
							contactText: payload.description,
							read: false,
							id: event.data[0].ID,
							time: event.data[0].clientTime,
							avatar: event.data[0].avatar,
						};
					} else if (payload.data == "cv") {
						obj = {
							cv: true,
							contactText: payload.description,
							id: event.data[0].ID,
							time: event.data[0].clientTime,
							avatar: event.data[0].avatar,
						};
					} else if (payload.data == "connect") {
						obj = {
							connect: true,
							contactText: payload.description,
							phoneShow: true,
							id: event.data[0].ID,
							time: event.data[0].clientTime,
							avatar: event.data[0].avatar,
						};
					} else if (payload.data == "img") {
						obj = {
							img: true,
							contactText: payload.description,
							id: event.data[0].ID,
							time: event.data[0].clientTime,
							avatar: event.data[0].avatar,
						};
					} else if (payload.data == "inte") {
						// 监听事件：面试可点击
						obj = {
							invite: true,
							contactText: JSON.parse(payload.description),
							id: event.data[0].ID,
							time: event.data[0].clientTime,
							avatar: event.data[0].avatar,
						};
					}
					this.wordList.push(obj);
					this.$nextTick(() => {
						if (this.destory && this.chartId) {
							this.scrollEvent();
						}
					});
				};
				// 监听对方发过来的消息
				this.$tim.on(TIM.EVENT.MESSAGE_RECEIVED, onMessageReceived);

				let conversationList = (event) => {
					this.chartList = event.data;
					// hyz：重新获取:交换列表:面试列表
					this.getExchangeList();
					this.getInterviewList();
				};
				// 监听会话列表
				this.$tim.on(TIM.EVENT.CONVERSATION_LIST_UPDATED, conversationList);
				// 监听消息的已读,如果已读回执之后会进入
				let onMessageReadByPeer = (event) => {
					this.wordList.forEach((item) => {
						if (item.mineMsg) {
							item.read = true;
						}
					});
				};
				this.$tim.on(TIM.EVENT.MESSAGE_READ_BY_PEER, onMessageReadByPeer);
			},
			// 调取会话列表
			readyList() {
				const loading = this.$loading({
					lock: true,
					text: "加载中...",
					spinner: "el-icon-loading",
					background: "rgba(0, 0, 0, 0.7)",
				});
				// 拉取会话列表
				let promise = this.$tim.getConversationList();
				promise.then((imResponse) => {
						let conversationList = imResponse.data.conversationList;
						console.log("拉取的会话列表", conversationList);
						this.chartList = conversationList;
						let positionObj = conversationList.map((item) => {
							if (item.lastMessage.payload.extension) {
								if (JSON.parse(item.lastMessage.payload.extension).id) {
									return {
										position_id: JSON.parse(item.lastMessage.payload.extension).jopId,
										user_id: JSON.parse(item.lastMessage.payload.extension).id,
										im_user_id: item.userProfile.userID,
									};
								}
							}
						});
						// console.log(positionObj)
						let positionObjOne = positionObj.filter((item) => {
							return item;
						});
						this.setConnect({
							positions: positionObjOne
						});
						loading.close();
					})
					.catch((imError) => {
						console.log(imError);
						loading.close();
					});
			},

			// 监听滚动条的高度，滚动到顶部后拉取聊天数据
			scrollEventSay(e) {
				if (!this.chartId) return;
				this.scrollHeight = e.srcElement.scrollHeight;
				if (e.srcElement.scrollTop == 0 && !this.isCompleted) {
					this.concatShow = false;
					this.getSayList({
						conversationID: `C2C${this.chartId}`,
						nextReqMessageID: this.nextReqMessageID,
						count: 15,
					});
				}
			},
			// 拉取某个人的会话数据
			getSayList(options) {
				// 打开某个会话时，第一次拉取消息列表，注意！第一次拉取时不要传入 nextReqMessageID
				let promise = this.$tim.getMessageList(options);
				promise.then((imResponse) => {
					let messageList = imResponse.data.messageList; // 消息列表。
					console.log("拉取某个人的会话数据", messageList);
					// 获取最后一个聊天内容并将扩展字段赋值给data中的extension
					if (messageList[messageList.length - 1].payload.extension) {
						this.extension = JSON.parse(
							messageList[messageList.length - 1].payload.extension
						);
					}
					let wordList = messageList.map((item) => {
						if (item.from == this.userId && item.payload.data == "text") {
							return {
								mineMsg: true,
								contactText: item.payload.description,
								read: item.isPeerRead ? true : false,
								id: item.ID,
								time: item.clientTime,
								avatar: item.avatar,
							};
						} else if (item.payload.data == "cv") {
							return {
								cv: true,
								contactText: item.payload.description,
								id: item.ID,
								time: item.clientTime,
								avatar: item.avatar,
							};
						} else if (item.from == this.userId && item.payload.data == "connect") {
							return {
								connect: true,
								contactText: item.payload.description,
								phoneShow: false,
								id: item.ID,
								time: item.clientTime,
								avatar: item.avatar,
							};
						} else if (item.from !== this.userId && item.payload.data == "text") {
							return {
								mineMsg: false,
								contactText: item.payload.description,
								read: false,
								id: item.ID,
								time: item.clientTime,
								avatar: item.avatar,
							};
						} else if (item.from !== this.userId && item.payload.data == "connect") {
							return {
								connect: true,
								contactText: item.payload.description,
								phoneShow: true,
								id: item.ID,
								time: item.clientTime,
								avatar: item.avatar,
							};
						} else if (item.payload.data == "inte") {
							return {
								invite: true,
								contactText: JSON.parse(item.payload.description),
								id: item.ID,
								time: item.clientTime,
								avatar: item.avatar,
							};
						} else if (item.payload.data == "cvText") {
							return {
								mineMsg: true,
								contactText: "附件简历已发出",
								read: item.isPeerRead ? true : false,
								time: item.clientTime,
								id: item.ID,
								avatar: item.avatar,
							};
						}
					});
					let wordListOne = wordList.filter((item) => {
						return item;
					});
					if (this.concatShow) {
						this.wordList = wordListOne;
						this.scrollEvent();
					} else {
						for (let i = wordListOne.length - 1; i >= 0; i--) {
							this.wordList.unshift(wordListOne[i]);
						}
						this.$nextTick(() => {
							let msg = document.getElementsByClassName("chart-rightr-item")[0]; // 获取对象
							msg.scrollTop = msg.scrollHeight - this.scrollHeight; // 滚动高度
						});
					}
					this.nextReqMessageID = imResponse.data.nextReqMessageID; // 用于续拉，分页续拉时需传入该字段。
					this.isCompleted = imResponse.data.isCompleted; // 表示是否已经拉完所有消息。isCompleted 为 true 时，nextReqMessageID 为 ""。
				});
			},
			// im发送自定义消息
			sendSay(payload) {
				var that = this;
				// 发送文本消息，Web 端与小程序端相同
				// 1. 创建消息实例，接口返回的实例可以上屏
				let message = this.$tim.createCustomMessage({
					to: this.chartId.toString(),
					conversationType: TIM.TYPES.CONV_C2C,
					payload,
				});
				// 2. 发送消息
				let promise = this.$tim.sendMessage(message);
				promise.then((imResponse) => {
						// console.log("发送消息", imResponse);
						// 将某会话下所有未读消息已读上报
						let promiseOne = this.$tim.setMessageRead({
							conversationID: `C2C${this.chartId}`,
						});
						promiseOne.then(function(imResponse) {
								// 已读上报成功，指定 ID 的会话的 unreadCount 属性值被置为0
							}).catch(function(imError) {
								// 已读上报失败
								console.warn("setMessageRead error:", imError);
							});
						// 发送成功
						let obj;
						let payload = imResponse.data.message.payload;
						let message = imResponse.data.message;
						if (payload.data == "text") {
							obj = {
								mineMsg: true,
								contactText: payload.description,
								read: false,
								time: message.clientTime,
								id: message.ID,
								avatar: message.avatar,
							};
						} else if (payload.data == "cv") {
							obj = {
								cv: true,
								contactText: payload.description,
								time: message.clientTime,
								id: message.ID,
								avatar: message.avatar,
							};
						} else if (payload.data == "connect") {
							obj = {
								connect: true,
								contactText: payload.description,
								time: message.clientTime,
								phoneShow: false,
								id: message.ID,
								avatar: message.avatar,
							};
							// hyz add：保存交换电话记录
							let hr_id = that.posiObj.hr_id;
							if(hr_id){
								userApi.exchangePhone({'receiver': hr_id, 'is_hr':0, 'im_id':message.ID, 'create_time':message.clientTime}).then((res) => {
									console.log('===exchangePhone===',res)
									if(res.code==200) that.$util.msg(res.data);
								})
							}else{
								console.log('===',hr_id)
								that.$util.msg('hr_id 为空', "error");
							}
						} else if (payload.data == "cvText") {
							obj = {
								mineMsg: true,
								contactText: "附件简历已发出",
								read: false,
								time: message.clientTime,
								id: message.ID,
								avatar: message.avatar,
							};
						}
						
						// hyz：人才发送消息，hr就收到推送
						that.posiObj['jopId'] = that.posiObj.id;
						that.posiObj['hrId'] = that.posiObj.hr_id;
						that.posiObj['ent_name'] = that.posiObj.ent.ent_name;
						// toolApi.sendTpl(that.posiObj).then(res=>{
						// 	console.log('=========sendTpl=========',res)
						// })
						this.wordList.push(obj);
						this.scrollEvent();
					}).catch(function(imError) {
						// 发送失败
						console.warn("sendMessage error:", imError);
					});
			},

			// 选中的表情包
			selectEmoji(emojiKey) {
				this.textarea += emojiKey;
				this.emojiShow = false;
			},
			// 输入框绑定enter键
			handleSearchClick() {
				this.sendClick();
			},
			// 确定发送简历
			cvClick() {
				toolApi.getAnnexResume().then((res) => {
					this.cvShow = false;
					this.$refs.maskResume.show();
					if (res.code == 200) {
						if (res.data.annex_status == 0 || !res.data.annex_status) {
							this.msgText = "你还未上传附件简历";
						} else if (res.data.annex_status == 1) {
							this.msgText = "简历审核中";
						} else if (res.data.annex_status == 3) {
							this.msgText = res.data.annex_rejection;
						} else if (res.data.annex_status == 2) {
							this.annexObj = res.data;
						}
					}
				});
			},
			// 去上传附件简历
			toUploadClick() {
				this.$refs.maskResume.hide();
				this.$refs.appendixCv.show();
			},
			// 选中简历后确定弹窗
			submitClick() {
				// console.log(this.annexObj)
				// console.log(this.extension)
				if (this.msgText) {
					this.$util.msg("请先上传附件简历");
					return;
				}
				this.sendSay({
					data: "cvText",
					description: JSON.stringify(this.annexObj),
					extension: JSON.stringify(this.extension),
				});
				posiApi.addDelivery({
					position_id: this.extension.jopId
				});
				this.scrollEvent();
			},
			cvClickOne() {
				this.sendSay({
					data: "connect",
					description: "已拒绝发送简历",
					extension: JSON.stringify(this.extension),
				});
				this.scrollEvent();
			},
			sendPhoneOne(id) {
				if(this.exchangeList){
					for (let i = 0; i < this.exchangeList.length; i++) {
						let item = this.exchangeList[i];
						if(item.im_id == id){
							if(item.status != 0) {
								this.$util.msg("联系方式只能操作一次", "warning");
							}else{
								// 更新交换电话表
								this.upExchangePhone(id, 2);
								// 聊天框拒绝
								this.sendSay({
									data: "text",
									description: "已拒绝交换联系方式",
									extension: JSON.stringify(this.extension),
								});
								this.scrollEvent();
							}
							break;
						}
					}
				}else{
					console.log('exchangeList 为空')
				}
			},
			interestedClick() {
				this.sendSay({
					data: "text",
					description: "对不起，我觉得该职位不太适合我，祝您早日找到满意的工作人选",
					extension: JSON.stringify(this.extension),
				});
				this.scrollEvent();
			},
			sendPhone(id) {
				let phone;
				if (this.userDetail.phone)  phone = this.userDetail.phone;
				else return this.$util.msg("你还未绑定手机号码，请前往绑定", "warning");
				
				if(this.exchangeList){
					for (let i = 0; i < this.exchangeList.length; i++) {
						let item = this.exchangeList[i];
						if(item.im_id == id){
							if(item.status != 0) {
								this.$util.msg("联系方式只能操作一次", "warning");
							}else{
								// 更新交换电话表
								this.upExchangePhone(id, 1);
								// 聊天框同意
								this.sendSay({
									data: "text",
									description: phone,
									extension: JSON.stringify(this.extension),
								});
								this.scrollEvent();
							}
							break;
						}
					}
				}else{
					console.log('exchangeList 为空')
				}
			},
			// 发送交换电话的信息
			phoneClick() {
				this.sendSay({
					data: "connect",
					description: "请求HR交换联系方式已发出",
					extension: JSON.stringify(this.extension),
				});
				this.scrollEvent();
				this.phoneShow = false;
			},
			// 发送聊天信息
			sendClick() {
				if (this.textarea.trim() == "") {
					this.$util.msg("请输入内容", "warning");
					this.textarea = "";
					return;
				}
				toolApi.checkWords({
					words: this.textarea
				}).then((res) => {
					if (res.data.words.length > 0) {
						this.$util.msg(`包含违禁词${res.data.words[0]}，请重新输入`, "error");
						return;
					} else {
						this.sendSay({
							data: "text",
							description: this.textarea,
							extension: JSON.stringify(this.extension),
						});
						this.scrollEvent();
						this.textarea = "";
					}
				});
			},
			// 控制滚动条滚动到底部
			scrollEvent() {
				// 自动滚动到底部
				this.$nextTick(() => {
					let msg = document.getElementsByClassName("chart-rightr-item")[0]; // 获取对象
					msg.scrollTop = msg.scrollHeight + 10; // 滚动高度
				});
			},
			//   点击未读
			unreadClick() {
				this.unreadShow = !this.unreadShow;
				if (!this.unreadShow) {
					let chartList = this.chartList;
					this.chartList = chartList.filter((item) => {
						return item.unreadCount > 0;
					});
				} else {
					this.readyList();
				}
			},
			// 鼠标移入
			mouseenterClick(i) {
				this.pointerIndex = i;
			},
			// 点击联系人进行聊天
			userClick(id, extension) {
				// 判断是否包含扩展字段
				if (extension) {
					let extensionOne = JSON.parse(extension);
					this.posiDetail(extensionOne.jopId);
					this.extension = extensionOne;
				}
				this.chartId = id;
				this.scrollEvent();
				this.concatShow = true;
				this.getSayList({
					conversationID: `C2C${id}`,
					count: 15
				});
				// 将某会话下所有未读消息已读上报
				let promise = this.$tim.setMessageRead({
					conversationID: `C2C${id}`
				});
				promise.then(function(imResponse) {
					// 已读上报成功，指定 ID 的会话的 unreadCount 属性值被置为0
				}).catch(function(imError) {
					// 已读上报失败
					console.warn("setMessageRead error:", imError);
				});
			},
			// 扫一扫
			scanClick() {
				this.scanShow = !this.scanShow;
			},
		},
	};
</script>
<style lang="less" scoped>
	.my-resume {
		width: 100%;
		min-height: 100vh;
		background: #f6f6f8;

		.my-resume-ceneter {
			width: 100%;
			padding: 1.25rem 11.8125rem 2.125rem 11.9375rem;
			align-items: flex-start;

			.my-resume-ceneter-router {
				margin-left: 1.25rem;
				width: 100%;
				background: #ffffff;
				box-shadow: 1px 0px 20px 1px #f6f6f8;

				.interaction-heard {
					width: 100%;
					height: 44px;
					background: #fbfbfc;

					.heard-left {
						width: 23.3125rem;
						height: 100%;
						padding: 0 20px 0 30px;
						border-right: 1px solid #ededf1;
						color: #9fa3b0;

						.unread {
							div:first-child {
								width: 15px;
								height: 15px;
								background: #ffffff;
								border: 1px solid #e3e7ed;
								border-radius: 1px;
								margin-right: 7px;
							}

							img {
								width: 15px;
								height: 15px;
								margin-right: 7px;
							}
						}
					}

					.heard-right {
						width: 100%;
						padding: 0 30px;

						.user-right {
							color: #9fa3b0;

							.user-job {
								margin-left: 20px;

								div {
									margin: 0 8px;
								}
							}
						}

						.scan-img {
							z-index: 3;
							width: 12.625rem;
							height: 13.8125rem;
							top: 20px;
							right: 46px;

							img {
								width: 100%;
								height: 100%;
							}

							.code-img {
								width: 100%;
								height: 100%;
								padding: 30px 21px 21px 21px;

								img {
									width: 100%;
									height: 100%;
								}
							}
						}
					}
				}

				.interaction-center {
					width: 100%;
					height: 760px;

					.center-user-list {
						width: 23.3125rem;
						height: 100%;
						border-right: 1px solid #ededf1;

						.no-user {
							width: 100%;
							height: 100%;
							justify-content: center;
						}

						.have-user {
							width: 100%;
							height: 100%;
							overflow-y: auto;

							.have-user-item {
								width: 100%;
								height: 96px;
								background: #ffffff;
								padding: 0 21px 0 25px;
								color: #9fa3b0;
								font-size: 13px;

								img {
									width: 56px;
									height: 56px;
									border-radius: 50%;
									margin-right: 10px;
								}

								.user-item-center {
									width: 100%;
									height: 56px;
									align-items: flex-start;

									.ellip-text {
										-webkit-line-clamp: 1;
									}

									.item-user-text {
										width: 100%;

										div:first-child {
											margin-left: 6px;
										}

										div:last-child {
											font-size: 12px;
										}
									}
								}
							}

							.pointerClass {
								background: #f2f5fa;
							}
						}

						.have-user::-webkit-scrollbar {
							display: none;
						}
					}

					.center-user-say {
						width: 100%;
						height: 100%;

						.say-no {
							width: 100%;
							height: 100%;
							justify-content: center;
							color: #9fa3b0;

							img {
								width: 116px;
								height: 113px;
								margin-bottom: 98px;
							}
						}

						.say-interface {
							width: 100%;
							height: 100%;

							.say-interface-heard {
								width: 100%;
								height: 72px;
								margin-top: 2px;
								background: #fafafa;
								padding: 0 32px 0 28px;

								.heard-left {
									height: 100%;
									justify-content: space-evenly;
									color: #61687c;

									.left-top {
										div:nth-child(2) {
											margin: 0 13px;
										}

										div:last-child {
											margin-left: 13px;
										}
									}

									.left-bottom {
										div {
											margin-right: 14px;
										}
									}
								}

								.heard-right {
									height: 100%;
									color: #61687c;

									.right-left {
										height: 100%;
										justify-content: space-evenly;

										.right-left-bottom {
											div:last-child {
												margin-left: 13px;
											}
										}
									}

									img {
										width: 56px;
										height: 56px;
										margin-left: 23px;
									}
								}
							}

							// 表情包
							.el-expression {
								width: 435px;
								height: 250px;
								background: #ffffff;
								top: -251px;
								left: 0;
								box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
								padding: 10px;
								overflow-y: auto;
								display: flex;
								flex-wrap: wrap;

								img {
									width: 45px;
									height: 45px;
									padding: 5px;
								}
							}

							.el-expression::-webkit-scrollbar {
								display: none;
							}

							// 交换电话
							.el-phone-exchange {
								width: 336px;
								height: 187px;
								top: -170px;

								img {
									width: 100%;
									height: 100%;
								}

								.el-phone-center {
									width: 100%;
									height: 100%;
									top: 0;
									left: 0;
									display: flex;
									flex-direction: column;
									align-items: center;
									justify-content: space-evenly;
									font-size: 17px;
									color: #61687c;

									.el-phone-tab {
										color: #9599a5;
										font-size: 13px;

										div {
											width: 98px;
											height: 40px;
										}

										div:first-child {
											background: #ffffff;
											border: 1px solid #f6f6f8;
											border-radius: 2px;
											margin-right: 26px;
										}
									}
								}
							}

							.el-phone-exchange-one {
								left: -70px;
							}

							.send-box {
								width: 100%;
								height: 188px;
								border-top: 1px solid #ededf1;
								padding: 12px 30px 12px 32px;

								.send-box-heard {
									font-weight: 300;
									color: #61687c;
									font-size: 14px;

									.send-heard-left {
										img {
											width: 22px;
											height: 22px;
										}

										.heard-left-cv {
											margin-left: 20px;

											img {
												width: 18px;
											}

											div {
												margin-left: 5px;
											}
										}

										.heard-left-cv-one {
											img {
												width: 19px;
												height: 19px;
											}

											.phone-text {
												color: #e2e2e2;
											}
										}
									}

									.el-send-heard-right {
										width: 78px;
										height: 28px;
										border: 1px solid #126bf9;
										border-radius: 2px;
									}
								}

								.send-box-input {
									width: 100%;
									height: 100%;

									/deep/.el-textarea {
										width: 100%;
										height: 100%;
									}

									/deep/.el-textarea__inner {
										width: 100%;
										height: 100%;
										border: none;
										resize: none;
									}
								}

								.send-feet-button {
									div {
										width: 62px;
										height: 28px;
										border: 1px solid #dcdee1;
										border-radius: 14px;
										font-weight: 300;
										color: #dcdee1;
										font-size: 14px;
									}

									.area-active {
										border: 1px solid #126bf9 !important;
										color: #126bf9 !important;
									}
								}
							}

							.chart-rightr-item {
								width: 100%;
								height: 100%;
								overflow-y: auto;
								padding: 30px;

								.word {
									margin-bottom: 29px;

									img {
										width: 37px;
										height: 37px;
										border-radius: 50%;
										margin-right: 10px;
									}

									div {
										max-width: 300px;
										padding: 10px 15px;
										background: #f2f2f2;
										border-radius: 4px;
										color: #333;
										flex-wrap: wrap;
										justify-content: flex-start;
									}
								}

								.el-word-phone {
									margin-bottom: 29px;
									align-items: flex-end;

									img {
										width: 37px;
										height: 37px;
										border-radius: 50%;
										margin-right: 10px;
									}

									.word-phone-div {
										width: 240px;
										border-radius: 5px;
										background: #ffffff;
										box-shadow: 1px 1px 4px #888888;
										height: 80px;
										display: flex;
										flex-direction: column;

										>div:first-child {
											text-align: center;
											padding: 10px;
											border-bottom: 1px solid #eee;
										}

										.word-phone-button {
											flex: 1;
											height: 100%;
											align-items: center;

											div {
												width: 50%;
												height: 50%;
												display: flex;
												align-items: center;
												justify-content: center;
											}

											div:first-child {
												border-right: 1px solid #eee;
												color: #999;
											}
										}
									}

									.el-word-phone-div-text {
										width: 240px;
										border-radius: 5px;
										background: #ffffff;
										box-shadow: 1px 1px 4px #888888;
										height: 100px;
										display: flex;
										flex-direction: column;

										div:first-child {
											width: 100%;
											padding: 10px;
											border-bottom: 1px solid #eee;
											flex-wrap: nowrap;

											img {
												width: 40px;
												height: 40px;
											}
										}

										.word-phone-button-text {
											text-align: center;
											padding: 10px;
										}
									}
								}

								.word-time {
									margin-bottom: 25px;
									color: #9fa3b0;
								}

								.exchange {
									margin: 30px auto;
									width: 200px;
									height: 40px;
									border-radius: 20px;
									background: #f2f5f9;
								}

								.word-my {
									justify-content: flex-end;
									align-items: flex-start;
									margin-bottom: 29px;

									.have-read {
										// width: 30px;
										padding: 5px 8px;
										height: 16px;
										background: #75d37d;
										border-radius: 5px;
										font-size: 12px;
									}

									.read-false {
										background: #a3d3fc;
									}

									.word-my-center {
										margin-left: 10px;

										div {
											max-width: 300px;
											padding: 10px 15px;
											border-radius: 4px;
											word-break: break-all;
											flex-wrap: wrap;
											justify-content: flex-start;
										}

										img {
											width: 37px;
											height: 37px;
											border-radius: 50%;
											margin-left: 10px;
										}
									}
								}
							}

							.chart-rightr-item::-webkit-scrollbar {
								display: none;
							}
						}
					}
				}
			}
		}

		.mask-resume {
			width: 100%;
			margin-top: 30px;
			margin-bottom: 20px;

			.resume-div {
				margin-top: 10px;
				color: #999999;

				div {
					margin-left: 10px;
				}
			}

			.not-resume {
				width: 100%;
				height: 32px;
				background: #fff3e7;
				padding: 0 20px;
				color: #faad14;
				margin-top: 11px;

				img {
					width: 15px;
					height: 15px;
					margin-right: 10px;
				}

				.not-right {
					width: 50px;
					height: 20px;
					background: #faad14;
					border-radius: 10px;
				}
			}
		}

		.update-eidt {
			width: 100%;
			margin: 30px 0;
			padding-right: 30px;

			.eift-item {
				margin-bottom: 21px;

				.div-select {
					width: 290px;
					margin-left: 20px;

					/deep/.el-input--suffix {
						width: 290px;
					}

					/deep/.el-textarea__inner {
						height: 90px;
					}
				}

				.div-select-one {
					margin-left: 6px;
				}
			}

			.eift-item-one {
				margin-bottom: 21px;

				.select-item {
					margin-left: 20px;

					.select-item-div {
						width: 140px;

						/deep/.el-input {
							width: 140px;
						}
					}

					.select-item-div-one {
						margin-right: 10px;
					}
				}
			}
		}
	}
</style>
