<template>
    <div class="privacy">
        <!-- 头部 -->
        <logged-heard></logged-heard>
        <div class="head" v-if="detalObj">
            <div class="title-money">
                <span>{{ detalObj.position_name }}</span>
                <span v-if="detalObj.salary_min != '面议'">{{ detalObj.salary_min }}-{{ detalObj.salary_max }}</span>
                <span v-else>{{ detalObj.salary_min }}</span>
            </div>
            <div class="center">
                <span style="padding: 0 1.25rem">{{ detalObj.province }}·{{ detalObj.experience }}·{{
                    detalObj.education
                }}</span>
                <div class="box-right">
                    <div class="box" v-for="(item, index) in detalObj.ent.welfare.slice(0, 12)" :key="index">
                        <span>{{ item }}</span>
                    </div>
                </div>
            </div>
            <div class="option-1">
                <div class="option" v-show="detalObj.enterprise_id !== userDetail.enterprise_id">
                    <div class="box pointer" :class="detalObj.is_collection == 1 ? 'boxcolor' : ''"
                        @click="collectClick(detalObj.id)">
                        <img src="@/assets/forget/6.png" alt="" v-if="detalObj.is_collection == 0" />
                        <img src="@/assets/forget/9.png" alt="" v-else />
                        <span>{{ detalObj.is_collection == 0 ? "收藏" : "取消收藏" }}</span>
                    </div>
                    <!-- navTo('/interaction', 3) -->
                    <div class="box pointer" @click="
                        sendClick(
                            detalObj.hr.user_id,
                            detalObj.id,
                            detalObj.ent.ent_name,
                            detalObj.enterprise_id,
                            detalObj.ent.logo_url,
                            detalObj.hr.user_name,
                            detalObj.position_name,
                            detalObj.hr.id
                        )
                    ">
                        <span>{{ detalObj.is_connect == 0 ? '立即沟通' : '继续沟通' }}</span>
                    </div>
                    <div v-if="detalObj.is_delivery == 0" class="box pointer" @click="hint(detalObj.id)">
                        <span>申请职位</span>
                    </div>
                    <div v-else style="background-color: gray;" @click="hint(detalObj.id)" class="box pointer">
                        <span>已申请</span>
                    </div>
                </div>
                <div class="right-fill pointer" @click="navTo('/my-resume')"
                    v-if="hasLogin && userDetail.detail.completeness < 90">
                    <span>填写在线简历</span>
                </div>
            </div>
        </div>
        <!-- 内容区域 -->
        <div class="content-area" v-if="detalObj">
            <div class="left">
                <div class="notice-item">
                    <div class="item">
                        <div class="row">
                            <img :src="detalObj.hr.avatar_url ? detalObj.hr.avatar_url : avatar" alt="" />
                            <div class="item-text">
                                <div>{{ detalObj.hr.user_name }}</div>
                                <div>{{ detalObj.hr.online_status }}</div>
                            </div>
                        </div>
                        <!-- 职位描述 -->
                        <div class="title">
                            <ul>
                                <li>职位描述</li>
                                <li v-for="(item, index) in description" :key="index" style="word-break:break-all;">
                                    {{ item }}</li>
                                <li>公司介绍</li>
                                <li style="word-break:break-all;">
                                    {{ detalObj.ent.introduction ? detalObj.ent.introduction : "无" }}
                                </li>
                                <li>工作地址</li>
                                <li style="word-break:break-all;">
                                    {{ detalObj.province }}{{ detalObj.city }}{{ detalObj.address }}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="right-login">
                <div class="login">
                    <register :showCompany="true" :entObj="detalObj.ent" :entId="detalObj.enterprise_id"></register>
                </div>
            </div>
        </div>
        <!-- 完善信息的弹窗 -->
        <el-button type="text"></el-button>

        <el-dialog title="请选择支付方式" :visible.sync="showPay">
            <el-form ref="form" label-width="80px">
                <div>
                    <el-radio v-model="payType" label="1">支付宝支付</el-radio>
                    <el-radio v-model="payType" label="2">微信扫码支付</el-radio>
                    <el-button type="primary" @click="onSubmit">立即支付</el-button>
                </div>
                <!-- <el-form-item> -->
                    <div v-if="payType=='2' && buyObj" style="text-align:center;">
                        <img :src="buyObj.payment.qrcode_url" class="price-code" alt="" />
                        <div class="price-text-code flex-center">二维码有效期至</div>
                        <div class="price-text-code flex-center">{{buyObj.failure_time}}</div>
                        <div class="price-text-code row-center code-text-img flex-center">
                            <img src="../../../assets/glxt/16.png" class="wx-img" alt="" />
                            <span>扫码支付</span>
                        </div>
                    </div>
                <!-- </el-form-item> -->
            </el-form>
        </el-dialog>
        <!-- 底部 -->
        <feet-model></feet-model>
    </div>
</template>

<script>
import loggedHeard from "../../../components/loggedHeard.vue";
import feetModel from "../../../components/feet.vue";
import tabbarModel from "../../../components/tabbar.vue";
import register from "../../../components/register.vue";
import api from "../../../api/position";
import apiUser from "../../../api/user";
import {
    mapGetters,
    mapState
} from "vuex";
import TIM from "tim-js-sdk";
export default {
    components: {
        loggedHeard,
        feetModel,
        tabbarModel,
        register
    },
    computed: {
        ...mapGetters(["hasLogin"]),
        ...mapState(["userDetail"]),
    },
    data() {
        return {
            detalObj: null,
            // 职位描述
            description: [],
            avatar: require("@/assets/login/922028.png"),
            showPay: false,
            payType: '1',
            buyObj: '',
            isPay: false,
            clickType: 0
        };
    },

    created() {
        if (this.$route.query.id) {
            this.posiDeatil(this.$route.query.id);
        }
    },

    mounted() { },

    methods: {
        onSubmit() {
            var that = this;
            api.userH5Pay({
                id: this.$route.query.id,
                pay_type: this.payType,
                money: 0.01
            }).then((res) => {
                console.log('===userH5Pay===', res)
                if(res.code == 200){
                    this.buyObj = res.data;
                    if(parseInt(this.payType) == 1){
                        if(res.data != '订单已存在'){
                            let divForm = document.getElementsByTagName("divform");
                            if (divForm.length) document.body.removeChild(divForm[0]);
                            const div = document.createElement("divform");
                            div.innerHTML = this.buyObj.payment;
                            document.body.appendChild(div);
                            document.forms[1].setAttribute("target", "_blank");
                            document.forms[1].submit();
                            that.setInter();
                        }
                    }
                    if(parseInt(this.payType) == 2){
                        that.setInter();
                    }
                }
            });
        },
        // 轮训查看是否支付成功
        setInter() {
            var that = this
            this.setTime = setInterval(() => {
                apiUser.searchUserOrder({
                    order_no: this.buyObj.order_no
                }).then(res => {
                    console.log('===searchUserOrder===', res)
                    if (res.code == 200) {
                        if (res.data.pay_status) {
                            clearInterval(this.setTime)
                            that.showPay = false;
                            setTimeout(() => {
                                // this.$util.msg('支付成功')this.$router.push({ path ,query:{id}});
                                this.$confirm('购买干纺织会员服务，支付成功！', '提示', {
                                    confirmButtonText: '确定',
                                    cancelButtonText: '关闭',
                                    type: 'success',
                                    center: true
                                }).then(() => {
                                    // that.posiDeatil(this.$route.query.id);
                                    this.$router.go(0); //刷新当前页
                                }).catch(() => {
                                    // that.posiDeatil(this.$route.query.id);
                                    this.$router.go(0); //刷新当前页
                                });
                            }, 1000)
                        }
                    }
                })
            }, 1000);
        },
        sendClick(id, posiId, entName, ent_id, ent_logo, contacts, jop, hr_id) {
            this.clickType = 1;
            if(!this.isPay){
                this.showPay = true;
                return
            }

            if (!this.hasLogin) {
                this.$util.msg("请先登录", "warning");
                return;
            }
            if (!this.userDetail.detail || this.userDetail.detail.length == 0) {
                this.$util.msg("请先完善简历", "warning");
                return;
            } else {
                if (this.userDetail.detail.completeness < 90) {
                    this.$util.msg("你的简历完善度小于90%，请继续完善简历", "warning");
                    return;
                }
            }
            if (this.userDetail.user_type !== 1) {
                this.$emit('toggleClick')
                return;
            }
            if (this.detalObj.is_connect == 1) {
                this.$router.push({
                    path: "/interaction",
                    query: {
                        id,
                        posiId
                    }
                });
                return
            }
            let query = {
                user_id: this.userDetail.id, //用户id
                user: this.userDetail.name, //用户名称
                head: this.userDetail.avatar_url, //用户头像
                ent_name: entName, //企业名称
                ent_id, //企业id
                ent_logo, //企业logo
                contacts, //企业hr名字
                jopId: posiId, //工作id
                jop, //职位名称
                customStatus: 1, //沟通状态
                id: hr_id, //hrid
            };
            let message = this.$tim.createCustomMessage({
                to: id.toString(),
                conversationType: TIM.TYPES.CONV_C2C,
                payload: {
                    data: "text",
                    description: "打扰了,希望和你聊聊这个职位，是否方便呢",
                    extension: JSON.stringify(query),
                },
            });
            // 2. 发送消息
            let promise = this.$tim.sendMessage(message);
            promise
                .then((imResponse) => {
                    this.$router.push({
                        path: "/interaction",
                        query: {
                            id,
                            posiId
                        }
                    });
                })
                .catch(function (imError) {
                    // 发送失败
                    console.warn("sendMessage error:", imError);
                });
        },
        // 获取职位详情
        posiDeatil(id) {
            const loading = this.$loading({
                lock: true,
                text: '加载中...',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            api.posiDetail({
                id
            }).then((res) => {
                if (res.code == 200) {
                    this.detalObj = res.data;
                    this.description = res.data.description.split("\n");
                } else {
                    this.$router.go(-1)
                }
                loading.close()
            })

            if (this.hasLogin) {
                var that = this;
                apiUser.checkUserOrder({
                    position_id: this.$route.query.id
                }).then(res => {
                    console.log('===checkUserOrder===', res)
                    that.isPay = res.data.pay_status;
                })
            }
        },
        hint(id) {
            this.clickType = 0;
            if (!this.hasLogin) {
                this.$util.msg("请先登录", "warning");
                return;
            }
            if (this.userDetail.user_type !== 1) {
                this.$emit('toggleClick')
                return;
            }
            if (this.userDetail.detail.completeness < 90) {
                this.$alert("您的资料填写不完整，请完成个人信息", "温馨提示！", {
                    confirmButtonText: "确定",
                });
                return;
            }
            var that = this;
            if(!that.isPay){
                that.showPay = true;
                return
            }
            api.addDelivery({
                position_id: id
            }).then((res) => {
                if (res.code == 200) {
                    that.$util.msg(res.msg);
                    // this.$router.go(0); //刷新当前页
                    that.posiDeatil(this.$route.query.id);
                }
            });
        },
        // 收藏和取消
        collectClick(id) {
            if (!this.hasLogin) {
                this.$util.msg("请先登录", "warning");
                return;
            }
            if (this.userDetail.user_type !== 1) {
                this.$emit('toggleClick')
                return;
            }
            api.collectPosi({
                id
            }).then((res) => {
                if (res.code == 200) {
                    this.$util.msg(res.msg);
                    this.posiDeatil(id);
                }
            });
        },
        // 路由跳转
        navTo(path, id) {
            if (id) {
                this.$router.push({
                    path,
                    query: {
                        id
                    }
                });
                return;
            }
            this.$router.push({
                path
            });
        },
    },
};
</script>
<style lang="less" scoped>
.privacy {
    width: 100%;
    background: #fff;
    min-height: 100vh;

    .head {
        width: 100%;
        height: 13.625rem;
        background: #454555;
        margin-bottom: 1.875rem;

        .title-money {
            width: 82%;
            padding: 0 1.25rem;
            margin: 0 auto;
            padding-top: 3.75rem;

            span:nth-child(1) {
                font-size: 2rem;
                font-weight: 400;
                color: #ffffff;
            }

            span:nth-child(2) {
                font-size: 1.875rem;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #fa6a43;
                padding: 0 0.625rem;
            }
        }

        .center {
            width: 82%;
            margin: auto;
            font-size: 0.875rem;
            font-weight: 400;
            color: #b5b8c1;
            padding-top: 1.1875rem;

            .box-right {
                float: right;
                margin-top: -20px;

                .box {
                    width: 5.75rem;
                    display: inline-block;
                    height: 2rem;
                    margin-right: 1.25rem;
                    text-align: center;
                    line-height: 2rem;
                    border: 0.0625rem solid #ffffff;
                    border-radius: 3px;
                }
            }
        }

        .option-1 {
            width: 82%;
            margin: 1.25rem auto;

            .option {
                .box {
                    width: 8.125rem;
                    height: 2.8125rem;
                    text-align: center;
                    float: left;
                    line-height: 2.8125rem;
                    margin-left: 1rem;
                    border: 0.0625rem solid #ffffff;

                    img {
                        width: 0.875rem;
                        height: 0.875rem;
                        position: relative;
                        display: inline-block;
                        margin-right: 0.5rem;
                    }

                    span {
                        font-size: 1rem;
                        font-weight: 400;
                        color: #ffffff;
                    }
                }

                .boxcolor {
                    border: 1px solid #6da4fb;

                    span {
                        color: #6da4fb;
                    }
                }
            }

            .right-fill {
                float: right;
                padding: 0.5rem 12rem;
                font-size: 0.875rem;
                font-weight: 400;
                color: #fa6a43;

                span {
                    cursor: pointer;
                }
            }
        }
    }

    .content-area {
        width: 81%;
        margin: auto;
        display: flex;

        .left {
            width: 78%;
            border-right: 1px solid #f4f4f6;

            .notice-item {
                .item {
                    margin-bottom: 2rem;
                    padding-bottom: 1.875rem;
                    border-bottom: 1px solid #eee;

                    img {
                        width: 3.75rem;
                        height: 3.75rem;
                        border-radius: 50%;
                        margin-right: 18px;
                    }

                    .item-text {
                        font-size: 0.875rem;
                        color: #51586d;
                        margin-top: 0.5rem;

                        div:first-child {
                            font-size: 1.25rem;
                            margin-bottom: 0.25rem;
                            font-weight: 400;
                            color: #424a5e;
                        }
                    }
                }

                .item:last-child {
                    margin-bottom: 0;
                    border-bottom: none;
                }
            }

            .title {
                width: 61.6875rem;
                padding-top: 1.875rem;
                line-height: 2.875rem;

                ul li {
                    list-style-type: none;
                    font-size: 0.875rem;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #61687c;
                }
            }
        }

        .right-login {
            width: 19.8125rem;
            height: 41.6875rem;
            margin-left: 1.25rem;
            float: left;
            align-items: flex-start;
            margin-bottom: 1.25rem;

            .login {
                width: 19.8125rem;
                height: 21.6875rem;
                // background: #126bf9;
                margin-bottom: 1.25rem;
            }

            .have-seen {
                width: 19.8125rem;
                height: 19.5rem;
                background: #ffffff;
            }
        }
    }
}
</style>
